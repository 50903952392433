export default {
  cartAuctionIds: [],
  setCartAuctionIds(cartAuctionIds) {
    this.cartAuctionIds = cartAuctionIds
  },
  existsInCart(auctionId) {
    return this.cartAuctionIds.includes(auctionId);
  },
  addToCartItems(auctionId) {
    this.cartAuctionIds.push(auctionId)
  },
  removeFromCartItems(auctionId) {
    this.cartAuctionIds = this.cartAuctionIds.filter(id => id !== auctionId)
  },
  async onToggleCart(auctionId) {
    if (this.existsInCart(auctionId)) {
      return window.location.href = route('cart.show')
    }

    this.existsInCart(auctionId)
      ? this.removeFromCartItems(auctionId)
      : this.addToCartItems(auctionId)

    try {
      const response = await window.axios.post(route('auctions.cart.toggle', auctionId))
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(response.data.dataLayer)
    } catch (err) {
      if (err.response.status === 422) {
        // undo adding or removing when an error occurs
        this.existsInCart(auctionId)
          ? this.removeFromCartItems(auctionId)
          : this.addToCartItems(auctionId)

        return alert(err.response.data.message)
      }
    }
  },
}
