export const showAuctionModal = (auctionId, tab = 'details', data = null, mode = null) => {
  window.dispatchEvent(
    new CustomEvent('show-auction-modal', {
      detail: { auctionId, tab, data, mode },
    })
  )
}

export const showModalIfParamsAreProvided = () => {
  const url = new URLSearchParams(window.location.search)

  if (url.get('quick_view')) {
    // requires the smallest delay for quick view to register the listener
    setTimeout(() => {
      showAuctionModal(url.get('quick_view'), url.get('tab'))
    }, 100)
  }
}
