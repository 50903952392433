// import * as Sentry from '@sentry/browser';
// import sentryConfig from './Utils/sentry.js'
//
// if (import.meta.env.PROD) {
//   Sentry.init({
//     dsn: "https://1ea0c30271c78155078b9c54c19eb893@o4507704896061440.ingest.us.sentry.io/4507704902483968",
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.replayIntegration({
//         maskAllText: false,
//         blockAllMedia: false,
//       }),
//     ],
//     ...sentryConfig
//   })
//
//   window.addEventListener('setSentryUser', (event) => {
//     Sentry.setUser(event.detail)
//   })
// }

import './alpine'
import './filepond.js'
import './editor.js'
import { Livewire } from '../../vendor/livewire/livewire/dist/livewire.esm'
import instantsearch from 'instantsearch.js'
import insightsClient from 'search-insights'
import * as instantsearchWidgets from 'instantsearch.js/es/widgets'
import * as instantsearchHelpers from 'instantsearch.js/es/helpers'
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares'
import { connectRange } from 'instantsearch.js/es/connectors'
import { algoliaSearchClient, apiKey, appId } from '@/Search/utils'
import { autocompleteSearch } from '@/Search/autocomplete'
import axios from 'axios'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { route as ZiggyRoute } from '../../vendor/tightenco/ziggy'
import { history } from 'instantsearch.js/es/lib/routers'
import { showAuctionModal, showModalIfParamsAreProvided } from '@/Utils/quickViewModal'
import { startCountdownTick } from '@/Utils/timeleft'
import he from 'he'
import EmblaCarousel from 'embla-carousel'
import EmblaAutoScroll from 'embla-carousel-auto-scroll'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-youtube/dist/Youtube.min.js'
import 'filepond/dist/filepond.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import '@algolia/autocomplete-theme-classic/dist/theme.css'
import { beforeSend } from './Utils/sentry.js'

// start global timer ticks
startCountdownTick()

// Global app helpers
window.showAuctionModal = showAuctionModal
window.showModalIfParamsAreProvided = showModalIfParamsAreProvided

// helper to encode html entities in algolia templates
window.he = he

// pusher
window.Pusher = Pusher

// echo
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  forceTLS: true,
})

// axios
let token = document.head.querySelector('meta[name="csrf-token"]')

window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

if (token) {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
}

window.route = (route, params) => {
  return ZiggyRoute(window.locale + '.' + route, params)
}

// Embla
window.EmblaCarousel = EmblaCarousel
window.EmblaAutoScroll = EmblaAutoScroll

// Video JS
window.videojs = videojs

// Algolia -> Autocomplete
window.Autocomplete = autocompleteSearch

// Algolia -> Instantsearch
insightsClient('init', { appId, apiKey, useCookie: true })

// set a global userToken by using user id
if (window.userId) {
  insightsClient('setUserToken', 'user-' + window.userId)
}

// instantsearch
window.instantsearch = instantsearch
window.instantsearchWidgets = instantsearchWidgets
window.instantsearchHelpers = instantsearchHelpers
window.instantsearchHistory = history
window.instantsearchConnectRange = connectRange

window.algoliaInsightsMiddleware = createInsightsMiddleware({
  insightsClient: insightsClient,
})

window.algoliaSearchClient = algoliaSearchClient

Livewire.start()
