const getDeepPaths = (obj, path = '', paths = []) => {
  for (const key of Object.keys(obj)) {
    const newPath = path ? `${path}.${key}` : key
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      getDeepPaths(obj[key], newPath, paths)
    } else {
      paths.push(newPath)
    }
  }
  return paths
}

const deepValueChanged = (obj1, obj2, ignorePaths = []) => {
  const paths1 = getDeepPaths(obj1)
  const paths2 = getDeepPaths(obj2)

  for (const path of paths1) {
    if (ignorePaths.includes(path)) continue
    const value1 = getDeepValue(obj1, path)
    const value2 = getDeepValue(obj2, path)
    if (value1 !== value2) return true
  }

  for (const path of paths2) {
    if (ignorePaths.includes(path) || paths1.includes(path)) continue
    return true
  }

  return false
}

const getDeepValue = (obj, path) => {
  const parts = path.split('.')
  let current = obj
  let key = ''

  for (let i = 0; i < parts.length; i++) {
    key += parts[i]
    if (current[key] !== undefined) {
      current = current[key]
      key = ''
    } else {
      key += '.'
    }
  }

  return key === '' ? current : undefined
}

export default (obj1, obj2, ignore = []) => {
  return deepValueChanged(obj1, obj2, ignore)
}
