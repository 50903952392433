import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm'
import price from '../Utils/price'
import * as Sentry from '@sentry/browser'

export default {
  biddingAuctions: null,
  maxBids: {},
  curBids: {},
  auctionsWithNewBids: [],
  setBiddingAuctions(biddingAuctions) {
    this.biddingAuctions = biddingAuctions

    // initialise max bid values for each auction the user is bidding on
    if (biddingAuctions) {
      biddingAuctions.forEach(auction => {
        this.maxBids[auction.id] = auction.max_bid
        this.curBids[auction.id] = auction.cur_bid
      })
    }
  },
  setAuctionsWithNewBids(auctionsWithNewBids) {
    if (!Array.isArray(auctionsWithNewBids)) {
      // Note: this is a temporary fix to suppress excessive errors in sentry,
      // whilst preventing anything from breaking. Drop this isArray check when
      // a server-side fix is in place.
      if (typeof auctionsWithNewBids === 'object') {
        auctionsWithNewBids = Object.values(auctionsWithNewBids)
      } else {
        auctionsWithNewBids = []
      }
    }

    this.auctionsWithNewBids = auctionsWithNewBids
  },
  addToBiddingAuctions(auction) {
    if (Alpine.store('user').hasUser()) {
      this.biddingAuctions.push(auction)
    }
  },
  hasBidsForAuction(auctionId) {
    if (Alpine.store('user').hasUser()) {
      return this.biddingAuctions.some(auction => auction.id === auctionId)
    }
    return false
  },
  hasNewBidsForAuction(auctionId) {
    if (!Array.isArray(this.auctionsWithNewBids)) {
      // Note: this is a temporary fix to suppress excessive errors in sentry,
      // whilst preventing anything from breaking. Drop this isArray check when
      // a server-side fix is in place.
      return false
    }

    return this.auctionsWithNewBids.indexOf(auctionId) !== -1
  },
  getMaxBidForUser(auctionId, forHumans = true) {
    const maxBid = this.maxBids[auctionId] ? this.maxBids[auctionId] : null

    if (this.hasBidsForAuction(auctionId) && maxBid) {
      return forHumans
        ? price(maxBid, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
        : maxBid
    }
    return null
  },
  setMaxBidForUser(auctionId, maxBid) {
    this.maxBids[auctionId] = maxBid
  },
  getCurrentBidForUser(auctionId) {
    const currentBid = this.curBids[auctionId] ? this.curBids[auctionId] : null

    if (this.hasBidsForAuction(auctionId) && currentBid) {
      return price(currentBid, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
    }
  },
  setCurrentBidForUser(auctionId, currentBid) {
    this.curBids[auctionId] = currentBid
  },
}
