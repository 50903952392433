export default {
  data: {},

  setAuctionState(auctionData) {
    // merge the new data with the old data
    if (this.data[auctionData.id] !== undefined) {
      this.data[auctionData.id] = {
        ...this.data[auctionData.id],
        ...auctionData,
      }
    } else {
      this.data[auctionData.id] = auctionData;
    }
  },

  exists(auctionId) {
    return this.data[auctionId] !== undefined;
  },

  getHighBidderId(auctionId, currentHighBidderId) {
    if (this.exists(auctionId) && this.data[auctionId].high_bidder_id !== undefined) {
      return this.data[auctionId].high_bidder_id;
    }
    return currentHighBidderId;
  },

  getNumBids(auctionId, currentNumBids) {
    if (this.exists(auctionId) && this.data[auctionId].num_bids !== undefined) {
      return this.data[auctionId].num_bids;
    }
    return currentNumBids;
  },

  getCurrentPrice(auctionId, currentPrice) {
    if (this.exists(auctionId) && this.data[auctionId].next_bid !== undefined) {
      return this.data[auctionId].next_bid;
    }

    return currentPrice;
  },

  getReserveMet(auctionId, currentReserveMet) {
    if (this.exists(auctionId) && this.data[auctionId].reserve_met !== undefined) {
      return this.data[auctionId].reserve_met;
    }

    return currentReserveMet;
  },

  getEndsAt(auctionId, currentEndsAt) {
    if (this.exists(auctionId) && this.data[auctionId].ends_at !== undefined) {
      return this.data[auctionId].ends_at
    }

    return currentEndsAt;
  },

  isOpen(auctionId, currentStatus) {
    if (this.exists(auctionId) && this.data[auctionId].status !== undefined) {
      return this.data[auctionId].status === 'open';
    }

    return currentStatus === 'open';
  },

  isClosed(auctionId, currentStatus) {
    if (this.exists(auctionId) && this.data[auctionId].status !== undefined) {
      return this.data[auctionId].status === 'closed' || this.data[auctionId].status === 'suspended';
    }

    return currentStatus === 'closed' || currentStatus === 'suspended';
  },

  getLastSsrStatusCheck(auctionId) {
    // the last time we checked the SSR status of the auction
    if (this.exists(auctionId) && this.data[auctionId].last_ssr_status_check !== undefined) {
      return this.data[auctionId].last_ssr_status_check;
    }

    return null;
  },

  setLastSsrStatusCheck(auctionId, lastStatusCheck) {
    // set the last time we checked the SSR status this is used to prevent multiple requests
    this.setAuctionState({
      id: auctionId,
      last_ssr_status_check: lastStatusCheck,
    });
  },

  async checkSsrStatus(auctionId) {
    const response = await axios.get(route('auctions.ends', auctionId))

    this.setAuctionState({
      id: auctionId,
      ends_at: response.data.ends,
      status: response.data.status,
      last_ssr_status_check: new Date(),
    })
  },

  isWinning(auction, userId) {
    return this.getHighBidderId(auction.id, auction.high_bidder_id) === userId && this.getReserveMet(auction.id, auction.reserve_met);
  },
}
