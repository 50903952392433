import { reactive } from "vue"

const liveEventPresence = reactive({
  users: {},
  limbo: {},
  setUser(user) {
    this.users[user.id] = user
  },
  here(users) {
    this.users = {}
    users.forEach((user) => this.setUser(user))
  },
  joining(user) {
    this.setUser(user)

    // User is back, so we'll remove them from limbo.
    if (this.limbo[user.id]) {
      clearTimeout(this.limbo[user.id])
      delete this.limbo[user.id]
    }
  },
  removeUser(user) {
    if (this.users[user.id]) {
      delete this.users[user.id]
    }
  },
  leaving(user) {
    // Page loads cause the user to leave and rejoin channels which causes
    // their status to flicker, so we'll throw the user into limbo for 5
    // seconds and if they haven't returned by then, we'll remove them.
    this.limbo[user.id] = setTimeout(() => {
      this.removeUser(user)
      delete this.limbo[user.id]
    }, 5000)
  },
  count() {
    if (Object.keys(this.limbo).length > 0) {
      return Object.keys(this.users).length + Object.keys(this.limbo).length
    }

    return Object.keys(this.users).length
  }
})

export default liveEventPresence
