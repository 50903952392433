import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

export default {
  favouriteStoreIds: null,
  favouriteStores: null,
  setFavouriteStores(favouriteStores) {
    this.favouriteStores = favouriteStores.map(store => {
      return {
        id: store.id,
        name: store.name,
      }
    })
    this.favouriteStoreIds = favouriteStores.map(store => store.id)
  },
  getFavouriteStores() {
    return this.favouriteStores
  },
  getFavouriteStoresNames() {
    return this.favouriteStores.map(store => store.name)
  },
  isFavourite(storeId) {
    if (Alpine.store('user').hasUser()) {
      return this.favouriteStoreIds.includes(storeId)
    }
    return false
  },
  addToFavourites(storeId) {
    if (Alpine.store('user').hasUser()) {
      this.favouriteStoreIds.push(storeId)
    }
  },
  removeFromFavourites(storeId) {
    if (Alpine.store('user').hasUser()) {
      this.favouriteStoreIds = this.favouriteStoreIds.filter(id => id !== storeId)
    }
  },
  async onFavourite(storeId) {
    const route = window.location.pathname;

    const loginUrl = '/login'

    if (!Alpine.store('user').hasUser()) {
      return window.location.href = loginUrl
    }

    this.isFavourite(storeId)
      ? this.removeFromFavourites(storeId)
      : this.addToFavourites(storeId)

    try {
      await window.axios.post(route('stores.favourite', storeId)).then(
        response => {
          if (response.status === 200) {
            window.location.reload()
          }
        }
      )
    } catch (err) {
      // if the user has logged out in another tab, redirect them to the login page
      if (err.response.status === 401) {
        return window.location.href = loginUrl
      }
    }
  }
}
