export default {
  user: null,
  setUser(user) {
    this.user = user
  },
  hasUser() {
    return this.user !== null
  },
  getUserId() {
    if (this.hasUser()) {
      return this.user.id
    }
    return null
  },
}
