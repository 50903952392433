import { Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm'
import price from '@/Utils/price'
import timeleft from '@/Utils/timeleft'
import objectChanged from '@/Utils/objectChanged'
import __ from '@/Utils/translate'
import { ToWords } from 'to-words'
import {
  AuctionsStore,
  BidsStore,
  CartStore,
  ItemWatchStore,
  MessagesDropdownStore,
  StoresStore,
  UserStore,
  LiveEventPresenceStore
} from '@/Stores'

// Magic Methods
Alpine.magic('price', () => price)
Alpine.magic('timeleft', () => timeleft)
Alpine.magic('objectChanged', () => objectChanged)
Alpine.magic('__', () => __)
Alpine.magic(
  'toWords',
  () =>
    new ToWords({
      localeCode: 'en-US',
      converterOptions: {
        currency: true,
        ignoreDecimal: true,
        ignoreZeroCurrency: true,
      },
    })
)

// Stores
Alpine.store('user', UserStore) // must be first
Alpine.store('cart', CartStore)
Alpine.store('auctions', AuctionsStore)
Alpine.store('itemWatch', ItemWatchStore)
Alpine.store('stores', StoresStore)
Alpine.store('bids', BidsStore)
Alpine.store('messagesDropdown', MessagesDropdownStore)
Alpine.store('liveEventPresence', LiveEventPresenceStore)

window.Alpine = Alpine
