// note: in alpine context we should provide the translated string
// to this function as the 'message' rather than parse language on
// the client side. This function just replaces placeholders.
export default (message, replace) => {
  let translation = message

  if (replace) {
    Object.entries(replace).forEach(([key, value]) => {
      translation = translation.replace(':' + key, value)
    })
  }

  return translation
}
