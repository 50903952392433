export const getTimeLeft = (endDate) => {
  const endDateObj = new Date(endDate)
  const now = new Date()
  const diff = endDateObj.getTime() - now.getTime()

  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  const secs = Math.floor((diff % (1000 * 60)) / 1000)

  return {
    ended: diff < 0,
    days,
    hours,
    mins,
    secs,
  }
}

export const startCountdownTick = () => {
  if (window.countDownInterval === undefined) {
    let now = new Date()

    // wait until the next second to start countdown tick
    // so that it's always in sync with the devices clock
    let timeUntilNextSecond = 1000 - now.getMilliseconds()

    setTimeout(() => {
      // dispatch initial tick
      window.dispatchEvent(new CustomEvent('countdown-tick'))

      // start ticking every second
      window.countDownInterval = setInterval(
        () => window.dispatchEvent(new CustomEvent('countdown-tick')),
        1000
      )
    }, timeUntilNextSecond)
  }
}

export default (endDate, endText, format) => {
  const { ended, days, hours, mins, secs } = getTimeLeft(endDate)

  if (ended) {
    return endText
  }

  switch (format) {
    case 'short':
      if (days > 0) {
        // if it's more than 24 hours until the auction closes, show days and hours until closed
        return `${days > 0 ? `${days}d ` : ''} ${hours > 0 ? `${hours}h ` : ''}`
      } else if (hours > 0) {
        // if less than 24 hours away, show hours minutes and seconds until closed
        return `${hours < 10 ? '0' + hours : hours}:${mins < 10 ? '0' + mins : mins}:${secs < 10 ? '0' + secs : secs}`
      } else if (mins > 0) {
        // if less than an hour away, show minutes and seconds remaining until closed
        return `${mins}m ${secs}s`
      }
      // if less than a minute away, show seconds remaining until ending
      return `${secs > 0 ? `${secs}s` : endText}`
    case 'long':
      if (days <= 0 && hours <= 0 && mins <= 0 && secs < 1) {
        return endText
      }

      return `${days > 0 ? `${days}d ` : ''} ${hours > 0 ? `${hours}h ` : ''} ${mins > 0 ? `${mins}m ` : ''} ${secs > 0 ? `${secs}s` : '0s'}`
    default:
      throw new Error('Invalid format given to $timeleft')
  }
}
